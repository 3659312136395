.App {
  font-family: "Montserrat", sans-serif;
  background-color: #000000;
  display: flex;
  min-height: 100vh;
  color: white;
}

.SocialBar {
  padding-top: 5rem;
  display: flex;
  gap: 1rem;
  .SocialIcon {
    width: 5rem;
  }
}

.Header {
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  h1 {
    padding-top: calc(3rem + 1.5vw);
    font-size: calc(3rem + 1.5vw);
  }
}